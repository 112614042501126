<template>
  <div
    id="store-list"
    :style="{ backgroundColor: !isAllStore ? '' : '#F5F5F5' }"
  >
    <!-- <div class="breadcrumb" v-if="isGoods">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <p>您的位置：</p>
        <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>商品名？</el-breadcrumb-item>
        <svg-icon icon-class="ic-close" @click="$router.go(-1)" />
      </el-breadcrumb>
    </div> -->
    <!-- <transition name="el-fade-in"> -->
    <div
      v-if="store.enterpriseHomePageItems.length != 0"
      style="
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
      "
    >
      <breadcrumb
        color="#f8f8f8"
        :name="$route.query.brandName"
        v-if="isGoods"
      />

      <div class="goods-details" v-if="isGoods">
        <div class="top">
          <div class="commodity-img-box">
            <pic-zoom :url="info" :scale="2" class="commodity-img"></pic-zoom>
            <div class="commodity-img-list">
              <img
                src="@/assets/images/Goods-left.png"
                class="arrow arrow-left"
                @click="leftclick"
              />
              <div>
                <ul ref="ulWidth">
                  <li v-for="(pic, i) in filePath" :key="i">
                    <img
                      v-lazy="pic"
                      class="commodity-img-small"
                      :class="i === imgIndex ? 'onImages' : ''"
                      @click="selected(pic, i)"
                      :key="i"
                    />
                  </li>
                </ul>
              </div>
              <img
                src="@/assets/images/Goods-right.png"
                class="arrow arrow-right"
                @click="rightclick"
              />
            </div>
          </div>
          <div class="right">
            <div class="title">
              <h2>
                商品名称：<span :title="CommodityDetails.commodityName">{{
                  CommodityDetails.commodityName
                }}</span>
              </h2>
              <p>
                商品条形码：<span>{{ CommodityDetails.barcode }}</span>
              </p>
            </div>
            <div class="price-box">
              <p>
                零售价：
                <span
                  >￥<i>{{
                    preferentialTime
                      ? PriceDetails.preferentialRetailPrice
                      : PriceDetails.retailPrice | Price
                  }}</i></span
                >
                <!-- <span>￥<i>104.00</i></span> -->
                <span v-if="preferentialTime" class="line_through"
                  >[<i>￥{{ PriceDetails.retailPrice }}</i
                  >]</span
                >
                <!-- <span class="line_through">[<i>￥450.00</i>]</span> -->
              </p>
              <p>
                批发价：
                <span
                  >￥<i>{{
                    preferentialTime
                      ? PriceDetails.preferentialTradePrice
                      : PriceDetails.tradePrice | Price
                  }}</i></span
                >
                <!-- <span>￥<i>90.00</i></span> -->
                <span v-if="preferentialTime" class="line_through"
                  >[<i>￥{{ PriceDetails.tradePrice }}</i
                  >]</span
                >
                <!-- <span class="line_through">[<i>￥300.00</i>]</span> -->
              </p>
              <p>
                （起订量：
                <span
                  >≥{{
                    preferentialTime
                      ? PriceDetails.pTradeMinimumQuantity
                      : PriceDetails.tradeMinimumQuantity
                  }}{{ CommodityDetails.prickle }}</span
                >）
                <!-- <span>10／千克</span> -->
              </p>
            </div>
            <div class="content">
              <div>
                <!-- CommodityDetails -->
                <p>
                  生产厂商：<span :title="CommodityDetails.manufacturer">{{
                    CommodityDetails.manufacturer
                  }}</span>
                </p>
                <p>
                  归属品牌：<span>{{ CommodityDetails.brandName }}</span>
                </p>
                <!-- <p>主要成分：<span>——</span></p> -->

                <p>
                  商品重量：<span>{{ CommodityDetails.weight }}千克</span>
                </p>
                <p>
                  储藏方法：<span>{{ CommodityDetails.conditions }}</span>
                </p>
                <!-- <p>特产品类：<span>——</span></p> -->
                <p>
                  保质期限：<span>{{ CommodityDetails.expirationDate }}天</span>
                </p>
              </div>
              <div>
                <p>
                  厂商地址：<span :title="CommodityDetails.address">{{
                    CommodityDetails.address
                  }}</span>
                </p>
                <p>
                  商品产地：<span
                    >{{ CommodityDetails.province
                    }}{{ CommodityDetails.city }}</span
                  >
                </p>
                <p>
                  商品规格：<span>{{ CommodityDetails.specsParameter }}</span>
                </p>
                <!-- <p>等级：　<span>——</span></p> -->
                <p>
                  计量单位：<span>{{ CommodityDetails.prickle }}</span>
                </p>
                <!-- <p>
                商品类目：<span>{{ CommodityDetails.enView }}</span>
              </p> -->
              </div>
              <p class="td">
                商品特点：
                <span>{{
                  CommodityDetails.characteristics === null
                    ? "无"
                    : CommodityDetails.characteristics
                }}</span>
              </p>
            </div>
            <!-- <div class="content">
            <div>
              <p>生产厂商：<span>北京正大果业有限公司</span></p>
              <p>保质期：<span>7 天</span></p>
              <p>储藏方法：<span>低温保鲜</span></p>
              <p>包装形式：<span>礼盒装</span></p>
              <p>特产品类：<span>长坝蜜柚</span></p>
            </div>
            <div>
              <p>厂址：<span>建国门内大街7号光华长安大厦15层</span></p>
              <p>品牌：<span>正大领鲜</span></p>
              <p>规格：<span>5.0kg</span></p>
              <p>产地：<span>广东韶关</span></p>
              <p>种类：<span>红心柚</span></p>
            </div>
          </div> -->
            <i class="dotted-line"></i>
            <div class="view-trace-tracing" @click="goTrackBack">
              <svg-icon icon-class="eye" />
              认证查询
            </div>
          </div>
        </div>
        <p class="gl">
          <img src="@/assets/images/gl-left.png" alt="" />
          关联店铺
          <img src="@/assets/images/gl-right.png" alt="" />
        </p>
      </div>
      <div class="company" v-if="!isAllStore">
        <div>
          <img src="@/assets/images/company.png" alt="" />
          <h2>{{ store.enterpriseName }}</h2>
        </div>
        <div>
          <p>
            共<span>{{ store.total }}</span
            >个店铺<span>{{ store.sum }}</span
            >种商品
          </p>
        </div>
      </div>
      <div
        class="store-box"
        v-for="(list, i) in store.enterpriseHomePageItems"
        :key="list.storeId"
      >
        <div class="store">
          <img
            v-lazy="list.storeLogo"
            :key="list.storeLogo"
            @click="goStore(list.storeId, list.enterpriseId)"
          />
          <p @click="goStore(list.storeId, list.enterpriseId)">
            {{ list.storeName }}
          </p>
          <a @click="goStore(list.storeId, list.enterpriseId)">进入店铺首页</a>
        </div>
        <div class="goods-list">
          <!-- <img src="@/assets/images/store-list-left.png" class="left" /> -->
          <div class="goods" v-for="(item, index) in goods[i]" :key="index">
            <div
              @click="
                goGoodsDetails(item.barcode, list.enterpriseId, list.storeId)
              "
            >
              <img v-lazy="item.filePath" :key="item.filePath" />
            </div>
            <h4
              @click="
                goGoodsDetails(item.barcode, list.enterpriseId, list.storeId)
              "
              :title="item.commodityName"
            >
              {{ item.commodityName }}
            </h4>
            <p><span>￥</span>{{ item | test }}</p>
          </div>
          <!-- <img src="@/assets/images/store-list-right.png" class="right" /> -->
        </div>
      </div>
      <el-pagination
        :page-size="10"
        :pager-count="9"
        layout="prev, pager, next"
        :total="total"
        @prev-click="prev"
        @next-click="next"
        @current-change="current"
        v-if="isAllStore"
      >
      </el-pagination>
    </div>
    <el-empty :image-size="300" v-else></el-empty>
    <!-- </transition> -->
  </div>
</template>

<script>
import PicZoom from "vue-piczoom";
import { price, formatDate } from "@/utils/validate";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "store-list",
  components: {
    PicZoom,
    Breadcrumb,
  },
  data() {
    return {
      store: { enterpriseHomePageItems: [] },
      total: 0,
      pageNum: 1, //页码
      goods: "",
      isAllStore: false,
      isGoods: false,
      info: "",
      li: 0,
      ulW: 0, //ul的定位位置
      CommodityDetails: "", //商品详情
      filePath: "", //商品图片详情
      imgIndex: 0, //选择商品图片
      PriceDetails: "", //商品价格详情
      preferentialTime: true, //是否优惠
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getEnterprise();
      },
    },
  },

  filters: {
    //处理函数
    test(num) {
      return price(num);
    },
    Price(p) {
      if (p === undefined || p === null) {
        return "-";
      } else {
        if (String(Number(p)).indexOf(".")) {
          return p.toFixed(2);
        } else {
          return p;
        }
      }
    },
  },
  created() {
    this.getEnterprise();
    // console.log(this.$route);
    if (this.$route.path === "/StoreList") {
      this.isAllStore = true;
    }
    if (this.$route.query.barcode != undefined) {
      this.isGoods = true;
      this.getDetails();
    }

    // console.log(document.querySelector(".mouse-cover-canvas"));
    // document.querySelector(".mouse-cover-canvas").style.top = "0";
    // document.getElementsByClassName("mouse-cover-canvas")[0].style.top = "0";
  },
  mounted() {
    // this.$nextTick(function () {
    //   console.log(document.querySelector(".mouse-cover-canvas"));
    //   document.querySelector(".mouse-cover-canvas").className = "0";
    // });
    // if (location.href.indexOf("#reloaded") == -1) {
    //   location.href = location.href + "#reloaded";
    //   location.reload();
    // }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction);
    this.pageNum = 1;
  },
  methods: {
    //获取店铺信息
    async getEnterprise() {
      const data = await this.$API.homePage.enterprise({
        object: {
          barcode: this.$route.query.barcode,
          commodityCode: "",
          enterpriseId: this.$route.params.id,
          like: this.$route.query.like,
        },
        pageNum: this.pageNum,
        pageSize: 10,
      });
      this.store = data.data.data;
      this.total = data.data.data.total;
      let goods = this.store.enterpriseHomePageItems.map((item) => {
        return {
          goods: item.storeCommodityItems,
        };
      });
      this.goods = goods.map((a, b, c) => {
        return a.goods.map((d) => {
          return {
            ...d,
            filePath: d.filePath.split(",")[0],
          };
        });
      });
      this.PriceDetails =
        data.data.data.enterpriseHomePageItems[0].storeCommodityItems[0];
      if (this.PriceDetails.preferentialEndTime === null) {
        this.preferentialTime = false;
      } else {
        let now = new Date();
        let m = now.getTime(); //当前时间戳
        let preferential = m - this.PriceDetails.preferentialEndTime; //计算优惠时间是否超过当前时间
        if (preferential > 0) {
          this.preferentialTime = false;
        }
      }
    },
    //去店铺首页
    goStore(id, enterpriseId) {
      this.$router.push({
        path: `/Store/HomePage/${id}`,
        query: { enterpriseId: enterpriseId },
      });
    },
    //去商品详情
    goGoodsDetails(barcode, enterpriseId, id) {
      let a = this.$router.resolve({
        path: `/Store/HomePage/${id}/GoodsDetails/${barcode}`,
        query: { enterpriseId: enterpriseId },
      });
      window.open(a.href, "_blank");
    },
    //获取商品详情
    async getDetails() {
      const data = await this.$API.store.getCommodityDetails({
        object: {
          barcode: this.$route.query.barcode,
          enterpriseId: this.$route.params.id,
        },
      });
      this.CommodityDetails = data.data.data;
      // this.filePath = data.data.data.filePath.split(",").map((pic, i) => {
      //   return i === 0 ? pic : "http://hwpicture.realbrand.net/" + pic;
      // });
      this.filePath = data.data.data.filePath.split(",");
      this.info = data.data.data.filePath.split(",")[0];
    },
    //上一页
    prev() {
      this.pageNum--;
      this.getEnterprise();
      this.backTop();
    },
    //下一页
    next() {
      this.pageNum++;
      this.getEnterprise();
      this.backTop();
    },
    //分页数字
    current(i) {
      this.pageNum = i;
      this.getEnterprise();
      this.backTop();
    },
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    backTop() {
      //点击回顶部
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    //放大镜
    selected(src, i) {
      this.info = src;
      this.imgIndex = i;
    },
    //小图左点击事件
    rightclick() {
      let Lilength = this.$refs.ulWidth.children.length;
      if (this.li < Lilength - 8) {
        this.li++;
        this.ulW += -73;
        this.$refs.ulWidth.style.left = this.ulW + "px";
      }
    },
    //小图右点击事件
    leftclick() {
      if (this.li > 0) {
        this.li--;
        this.ulW += 73;
        this.$refs.ulWidth.style.left = this.ulW + "px";
      }
    },
    goTrackBack() {
      // console.log(this.$route);
      this.$router.push({
        name: "track-back",
        query: {
          id: this.$route.query.barcode,
          enterpriseId: this.$route.params.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#store-list {
  border-top: 1px solid #00aeeb;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding-bottom: 40px;
  .breadcrumb {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #f8f8f8;
    ::v-deep .el-breadcrumb {
      width: 1200px;
      display: flex;
      align-items: center;
      height: 55px;
      p {
        font-size: 14px;
        color: #999999;
      }
      span {
        color: #999999;
        font-weight: normal;
      }
      .el-breadcrumb__item:nth-child(3) {
        span {
          color: #333333;
        }
        i {
          display: none;
        }
      }
      .svg-icon {
        cursor: pointer;
        margin-left: 4px;
      }
    }
  }

  .goods-details {
    .gl {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      img {
        margin: 0 20px;
      }
    }
    .top {
      width: 1200px;
      margin: 24px 0 40px 0;
      display: flex;
      .commodity-img-box {
        display: flex;
        flex-direction: column;
        ::v-deep .magnifier-box {
          border: 1px solid #eee;
          img {
            // width: auto;
            // height: auto;
            width: 100%;
            height: 100%;
          }
        }
        .commodity-img {
          width: 460px;
          height: 460px;
          img {
            width: 460px;
            height: 460px;
          }
        }

        .commodity-img-list {
          margin-top: 14px;
          width: 460px;
          position: relative;
          div {
            width: 384px;
            overflow: hidden;
            position: relative;
            height: 60px;
            margin-left: 30px;
            ul {
              width: 1040px;
              position: absolute;
              left: 0;
              transition: all 0.5s;
              li {
                float: left;
                margin-left: 16px;
                .commodity-img-small {
                  width: 60px;
                  height: 60px;
                  border: 2px solid #fff;
                  cursor: pointer;
                }
                .onImages {
                  border: 2px solid #00aeeb;
                }
              }
            }
          }
          .arrow {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 15px;
            cursor: pointer;
          }
          .arrow-left {
            left: 0;
          }
          .arrow-right {
            right: 0;
          }
        }
      }
      .right {
        // width: 560px;
        height: 460px;
        margin-left: 24px;
        position: relative;
        .title {
          display: flex;
          line-height: 26px;
          color: #333333;
          justify-content: space-between;
          h2 {
            font-size: 20px;
            color: #888888;
            width: 520px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              color: #333333;
            }
          }
          p {
            font-size: 14px;
            color: #999999;
            span {
              color: #00aeeb;
            }
          }
        }
        .price-box {
          width: 716px;
          height: 68px;
          margin: 16px 0;
          display: flex;
          align-items: center;
          background: url("../../assets/images/price-bg.png");
          p {
            font-size: 14px;
            color: #999999;
            line-height: 16px;
            height: 20px;
            span:first-child {
              color: #ec382f;
              i {
                font-size: 22px;
              }
            }
            .line_through {
              margin-left: 4px;
              i {
                text-decoration: line-through;
              }
            }
          }
          p:first-child {
            width: 342px;
            margin-left: 8px;
          }
          p:nth-child(2) {
            margin-right: 2px;
          }
          p:last-child {
            line-height: 16px;
            height: 16px;
            span {
              // color: #00aeeb;
            }
          }
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          .td {
            font-size: 14px;
            height: 20px;
            color: #888888;
            display: flex;
            line-height: 20px;
            margin-bottom: 10px;
            span {
              width: 556px;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          div {
            flex: 1;
            p {
              font-size: 14px;
              height: 20px;
              color: #888888;
              display: flex;
              line-height: 20px;
              margin-bottom: 10px;
              span {
                width: 210px;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .dotted-line {
          width: 100%;
          border-top: 1px dashed #ccc;
          display: block;
          margin: 6px 0 20px 0;
        }
        .buy {
          height: 36px;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            display: flex;
            align-items: center;
            .el-input-number {
              line-height: 36px;
              width: 160px;
              height: 36px;
              margin-right: 20px;
              ::v-deep.el-input-number__decrease {
                width: 36px;
                height: 34px;
                top: 2px;
                line-height: 34px;
                color: #333;
                .el-icon-minus:before {
                  font-weight: 900;
                }
              }
              .el-input-number__decrease:hover {
                color: #333;
              }
              ::v-deep.el-input-number__increase {
                width: 36px;
                height: 34px;
                top: 2px;
                line-height: 34px;
                color: #333;
                .el-icon-plus:before {
                  font-weight: 900;
                }
              }
              .el-input-number__increase:hover {
                color: #333;
              }
              ::v-deep.el-input__inner {
                border-radius: 0;
                height: 36px;
                border-color: #ddd;
              }
              ::v-deep.el-input__inner:focus {
                border-color: none;
              }
            }
            p:last-child {
              color: #999999;
              i {
                color: #00aeeb;
                margin-right: 4px;
              }
            }
          }

          p:last-child {
            color: #999999;
            span {
              color: #00aeeb;
            }
          }
        }
        ::v-deep .el-dialog__wrapper {
          .el-dialog__body {
            text-align: center;
            font-size: 16px;
          }
          .el-dialog__footer {
            .el-button--primary {
              background: #00aeeb;
              border: #00aeeb 1px solid;
            }
            .el-button--default:hover {
              color: #00aeeb;
              background: rgba($color: #fff, $alpha: 0.2);
              border: rgba($color: #00aeeb, $alpha: 0.2) 1px solid;
            }
          }
        }
        .add-to-shopping-cart {
          width: 148px;
          height: 42px;
          background: #00aeeb;
          border-radius: 4px;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 42px;
          position: absolute;
          bottom: 0px;
          cursor: pointer;
          .svg-icon {
            font-size: 16px;
          }
        }
        .view-trace-tracing {
          width: 148px;
          height: 42px;
          background: rgba(33, 157, 206, 0.05);
          border-radius: 4px;
          font-size: 16px;
          color: #00aeeb;
          text-align: center;
          border: 1px solid #00aeeb;
          line-height: 40px;
          position: absolute;
          left: 0px;
          bottom: 0px;
          cursor: pointer;
          .svg-icon {
            font-size: 16px;
          }
        }
      }
    }
  }
  .company {
    width: 1200px;
    height: 60px;
    display: flex;

    margin-top: 30px;
    background: #f7f7f7;
    justify-content: space-between;
    img {
      width: 60px;
      height: 60px;
    }
    div {
      display: flex;
      height: 100%;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
        margin: 0 10px 0 18px;
      }
      h2 {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #333333;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        margin-right: 20px;
        span {
          color: #fc2c22;
        }
        span:nth-child(2) {
          margin-left: 8px;
        }
      }
    }
  }
  .store-box {
    background: #fff;
    margin-top: 20px;
    border: 1px solid #ddd;
    display: flex;
    .store {
      width: 200px;
      height: 224px;
      border-right: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 88px;
        height: 88px;
        margin: 20px 0 10px 0;
        cursor: pointer;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        cursor: pointer;
      }
      a {
        width: 96px;
        height: 28px;
        background: #00aeeb;
        border-radius: 2px;
        font-size: 12px;
        line-height: 28px;
        color: #ffffff;
        text-align: center;
        margin-top: 34px;
        cursor: pointer;
      }
    }
    .goods-list:hover {
      .left,
      .right {
        display: block;
      }
    }
    .goods-list {
      height: 224px;
      width: 1000px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      padding: 0 8px;

      .left,
      .right {
        position: absolute;
        top: 68px;
        display: none;
        cursor: pointer;
      }
      .left {
        left: 0;
      }
      .right {
        right: 0;
      }
      .goods {
        width: 164px;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          width: 140px;
          height: 140px;
          overflow: hidden;
          margin-top: 20px;
          cursor: pointer;
          img {
            width: 140px;
            height: 140px;
            transition: all 0.5s;
          }
          img:hover {
            transform: scale(1.08);
          }
        }
        h4 {
          width: 140px;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 10px 0 2px 0;
          cursor: pointer;
          text-align: center;
        }
        h4:hover {
          color: #00aeeb;
        }
        p {
          line-height: 22px;
          font-size: 16px;
          color: #f34546;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
  .el-pagination {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    ::v-deep button:hover {
      color: #00aeeb;
    }
    ::v-deep button {
      width: 34px;
      height: 34px;
      border: 1px solid #ddd;
      padding: 0;
      min-width: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 4px;
      i {
        margin-left: 1px;
      }
    }
    ::v-deep .el-pager {
      li {
        width: 34px;
        height: 34px;
        padding: 0;
        border: 1px solid #ddd;
        min-width: 0;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
        margin: 0 4px;
      }
      li.active {
        color: #00aeeb;
        border-color: #00aeeb;
      }
      .more {
        border: none;
        margin: 0;
        width: 26px;
      }
    }
  }
}
</style>
<style lang="scss">
.mouse-cover-canvas {
  top: 242px !important;
}
</style>